<template>

    <div v-if="isLoading">
        <div class="skeleton-wrapper" role="presentation">
            <div class="skeleton image"></div>
            <div class="skeleton producer"></div>
            <div class="skeleton title"></div>
            <div class="skeleton description"></div>
            <div class="skeleton price"></div>
            <div class="skeleton-availability-wrapper">
                <div class="skeleton text"></div>
                <div class="skeleton button"></div>
            </div>
        </div>
    </div>
    <template v-else>
        <div class="product-header">
            <a :href="productData.url">
                <figure v-html="getImagesHtml()"></figure>
            </a>
            <div class="product-labels">
                <div class="label" v-for="label in productData.labels">
                    <span :style="{ '--label-border-color': label.borderColor, '--label-text-color': label.textColor, '--label-background-color': label.backgroundColor }">{{ label.text }}</span>
                </div>
            </div>
            <div class="product-enviroLabels">
                <div class="label" v-for="label in productData.environmentLabels">
                    <a href="#" class="product-label-text"><span>{{ label.text }}</span></a>
                    <span class="product-label-icon" v-if="label.icon" v-html="label.icon"></span>
                </div>
            </div>
            <template v-if="productData.variants.length > 1">
                <div class="product-variants__label">{{ getTranslation('severalVariants') }}</div>
                <div class="product-variants">
                    <ul>
                        <li class="variant-list-item"
                            v-for="variant in productData.variants"
                            :class="{ active: variant.isActive }"
                            :data-variant-id="variant.variantId">
                            <a :href="variant.url">
                                <div class="product-variant__img" v-if="variant.thumbnail" v-html="variant.thumbnail"></div>
                                <div class="product-variant__text"></div>
                            </a>
                        </li>
                        <li v-if="productData.moreVariants">
                            <a :href="productData.moreVariants.url">
                                <div class="product-variant__text">{{ productData.moreVariants.text }}</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-if="productData.adminButtons">
                <div class="admin-edit-button-wrapper">
                    <a v-for="b in productData.adminButtons"
                       class="btn btn-sm py-2" :class="b.class"
                       :href="b.url" target="_blank">
                        <svg v-if="b.icon === 'plane'" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" class="d-block"><path fill="currentColor" d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
                        <svg v-if="b.icon === 'pencil'" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512" class="d-block"><path fill="currentColor" d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>
                        <span class="visually-hidden">{{ b.label }}</span>
                    </a>
                </div>
            </template>
        </div>
        <div class="product-body">
            <div class="product-brand">
                <a :href="productData.producer.url" v-if="productData.producer">{{ productData.producer.title }}</a>
            </div>
            <div class="product-title-and-add-to-favorites">
                <h3 class="product-title">
                    <a :href="productData.url">{{ productData.title }}</a>
                </h3>
                <a href="#" class="add-to-favorites"
                   :class="{ active: productData.favorite.isActive }"
                   :data-id="productData.variantId"
                   :data-logged-in="productData.favorite.isLoggedIn ? 1 : 0"
                   :data-redirect-url="productData.favorite.redirectUrl">
                    <span class="visually-hidden">{{ getTranslation('addToFavorites') }}</span>
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.53324 11.6245L11.5532 18.9045L18.5732 11.6245C19.3535 10.8152 19.7919 9.71762 19.7919 8.57313C19.7919 6.18984 17.9289 4.25781 15.6308 4.25781C14.5271 4.25781 13.4687 4.71246 12.6883 5.52174L11.5532 6.69892L10.4181 5.52174C9.63768 4.71246 8.57927 4.25781 7.47565 4.25781C5.17748 4.25781 3.31445 6.18984 3.31445 8.57313C3.31445 9.71762 3.75286 10.8152 4.53324 11.6245Z" stroke="#32363E" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
            <div class="product-description" v-if="productData.variantTitle">{{ productData.variantTitle }}</div>
        </div>
        <div class="product-footer">
            <div class="product-price" :data-price="productData.prices.current.number">
                <div class="product-price__now" v-if="productData.prices.needsPriceRequest">{{ getTranslation('priceOnRequest') }}</div>
                <template v-else>
                    <div class="withTax" :class="{'d-none' : ! productData.prices.showTax}">
                        <div class="price__before" v-if="productData.prices.isOnSale">
                            {{ productData.prices.originalWithVat.formatted }}
                        </div>
                        <div class="product-price__now">{{ productData.prices.currentWithVat.formatted }}</div>
                        <div class="product-price__vat">{{ getTranslation('inclTax') }}</div>
                    </div>
                    <div class="withoutTax" :class="{'d-none' : productData.prices.showTax}">
                        <div class="price__before" v-if="productData.prices.isOnSale">
                            {{ productData.prices.original.formatted }}
                        </div>
                        <div class="product-price__now">{{ productData.prices.current.formatted }}</div>
                        <div class="product-price__vat">{{ getTranslation('exclTax') }}</div>
                    </div>
                </template>
            </div>
            <div class="product-stock-and-cta">
                <div class="product-stock">{{ productData.stockLabel }}</div>
                <div class="product-cta">
                    <a :href="productData.url" class="bttn bttn--add-to-cart">
                        <span class="visually-hidden">{{ getTranslation('addToCart') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </template>
    
</template>

<script setup>
import { defineComponent, ref, defineProps, onBeforeUpdate, watchEffect } from "vue";

defineComponent({
    name: "ProductListItem"
});
const props = defineProps({
    productData: Object,
    ids: Array
});

const isLoading = ref(true);

onBeforeUpdate(() => {
    isLoading.value = ! props.productData;
});
watchEffect(() => {
    isLoading.value = !props.productData;
});

const getImagesHtml = () => {
    return props.productData.image + (props.productData.secondaryImage ? props.productData.secondaryImage : '');
}

const getTranslation = key => {
    return window.lindbakVueTranslations[key] || key;
}; 

</script>

<style scoped>
.skeleton-wrapper {
    min-height: 465px;
}
.skeleton-wrapper .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 0.125rem;
}
@keyframes skeleton-loading {
    0% {
        background-color: #fcfcfc;
    }
    100% {
        background-color: #ebebeb;
    }
}
.skeleton.image {
    width: 100%;
    position: relative;
}
.skeleton.image::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}
.skeleton-availability-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.skeleton.producer {
    margin-top: 0.5rem;
}
.skeleton.title {
    margin-top: 0.3rem;
    height: 1.5rem;
    width: 75%;
}
.skeleton.description {
    margin-top: 0.3rem;
    height: 1rem;
}
.skeleton.price {
    margin-top: 0.5rem;
    height: 1.125rem;
    width: 50%;
}
.skeleton.producer,
.skeleton-availability-wrapper .skeleton.text {
    width: 6rem;
    height: 1.125rem;
}
.skeleton-availability-wrapper .skeleton.button {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
</style>