import {includeAsyncScript} from "./helpers";

let productReserveContainer;
let productPriceRequestContainer;
export function initHubspot() {
    document.addEventListener('DOMContentLoaded', () => {
        productReserveContainer = document.getElementById('b3a5abe0-e5a3-42fd-b551-ea8748eb7ea9');
        productPriceRequestContainer = document.getElementById('6cc21129-2776-4537-9b56-a26eb280ed16');
        if (productReserveContainer instanceof HTMLElement || productPriceRequestContainer instanceof HTMLElement) {
            init();
        }
    });
}

async function init() {
    let scriptLoaded = false;
    const scriptTag = document.createElement('script');
    scriptTag.src = '//js.hsforms.net/forms/embed/v2.js';
    scriptTag.onload = () => {
        scriptLoaded = true;
    };
    let hubspotForm = document.querySelector('.priceRequestHubspotForm');
    hubspotForm.append(scriptTag);

    while (! scriptLoaded ) {
        await new Promise(r => setTimeout(r, 200));
    }
    const hubspotScript = document.createElement('script');
    if(productReserveContainer instanceof HTMLElement){
        hubspotScript.textContent = 'hbspt.forms.create({\n' +
            '            portalId: "8344941",\n' +
            '            formId: "b3a5abe0-e5a3-42fd-b551-ea8748eb7ea9"\n' +
            '        });';

        hubspotForm.append(hubspotScript);

    }
    if(productPriceRequestContainer instanceof HTMLElement){
        hubspotScript.textContent = 'hbspt.forms.create({\n' +
            '            portalId: "8344941",\n' +
            '            formId: "6cc21129-2776-4537-9b56-a26eb280ed16"\n' +
            '        });'
        hubspotForm.append(hubspotScript);
    }
}