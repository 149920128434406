import * as bootstrap from 'bootstrap';

export function initAccordionBlocks() {
    document.addEventListener('DOMContentLoaded', () => {
        initClicks();
    });
}

function initClicks() {
    document.addEventListener('click', (e) => {
        const maybeInternalLink = e.target.closest('a');
        if (maybeInternalLink
            && maybeInternalLink.hostname === window.location.hostname
            && maybeInternalLink.pathname === window.location.pathname
            && maybeInternalLink.href.indexOf('#')) {
            const targetAnchor = maybeInternalLink.hash.substring(1);
            const maybeCollapseElement = document.getElementById(targetAnchor);
            if (maybeCollapseElement && maybeCollapseElement.classList.contains('collapse-block')) {
                if (maybeCollapseElement.classList.contains('show')) {
                    e.preventDefault();
                }
                const collapse = bootstrap.Collapse.getOrCreateInstance(maybeCollapseElement);
                collapse.toggle();
            }
        }
    });
}