import "./app.scss";
import "leaflet/dist/leaflet.css";
import 'nouislider/dist/nouislider.css';
import * as bootstrap from 'bootstrap';
import * as Popper from '@popperjs/core';
import * as Popover from 'bootstrap/js/dist/popover';

//import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'waypoints/lib/noframework.waypoints.min';
import 'magnific-popup/dist/jquery.magnific-popup.min';
import 'superfish/dist/js/superfish.min';
import 'superfish/dist/js/hoverIntent';
import 'nouislider/dist/nouislider.min';

import { initJqueryExtensions } from "./js/jqueryExtensions";
import { initHeader } from "./js/header";
import { initSwiper } from "./js/swiper";
import { jarallax, jarallaxVideo } from "jarallax";
import { initLazyImages } from "./js/lazy-images";
import { initVideos } from "./js/video";
import { initBootstrapFormValidationStyles } from "./js/bootstrapFormValidationStyles";
import { initFrontendMisc } from "./js/frontendMisc";
// import { initWebshopSearch } from "./js/webshopSearch";
import { initWebshop } from "./js/webshop";
import { initMaps } from "./js/map";
import { initCart } from "./js/cart";
import { initMainSearch } from "./js/mainSearch";
import { initProduct } from "./js/product";
import { initWishlist } from "./js/wishlist";
import { initCheckoutStep1 } from "./js/checkoutStep1";
import { initCheckoutStep2 } from "./js/checkoutStep2";
import { initCheckoutStep3 } from "./js/checkoutStep3";
import { initMyPageFunctions } from './js/myPageFunctions';
import { initProducers } from "./js/producers";
import { initTaxSelector } from "./js/taxSelector";
import { initRegistration } from "./js/registration";
import { initBlocks } from "./js/blocks";
import { initSearchLandingPage } from "./js/searchLandingPage";
import { initVueApps } from "./js/vue-apps";
import { initHubspot } from "./js/hubspot";
import { initAccordionBlocks } from "./js/accordion-blocks";

initJqueryExtensions();
initHeader();
initVueApps();
initSwiper();
initLazyImages();
initVideos();
jarallaxVideo();
initBootstrapFormValidationStyles();
initFrontendMisc();
initWebshop();
// initWebshopSearch();
initProduct();
initMaps();
initCart();
initMainSearch();
initWishlist();
initCheckoutStep1();
initCheckoutStep2();
initCheckoutStep3();
initMyPageFunctions();
initProducers();
initTaxSelector();
initRegistration();
initBlocks();
initSearchLandingPage();
initHubspot();
initAccordionBlocks();

// Only used in dev
if (module.hot) {
    module.hot.accept();
}