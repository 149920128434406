export function initLazyImages() {
    document.addEventListener('DOMContentLoaded', () => {
        const lazyImages = document.querySelectorAll('img.lazyload');
        for (const lazyImage of lazyImages) {
            loadLazyImage(lazyImage);
        }
    });
}

function loadLazyImage(img) {
    if (img.classList.contains('loaded')) {
        return;
    }
    const dataSrc = img.getAttribute("data-src");
    const dataSrcSet = img.getAttribute("data-srcset");

    if (dataSrc) {
        const preloadImg = new Image();
        preloadImg.onload = () => {
            img.setAttribute("src", dataSrc);
            if (dataSrcSet) {
                img.setAttribute("srcset", dataSrcSet);
            }
            if (img.classList.contains('lazy-img-fluid')) {
                img.classList.remove('lazy-img-fluid', 'mw-100');
                img.classList.add('img-fluid');
            }
        };
        preloadImg.onerror = () => {};
        preloadImg.src = dataSrc;
    }
    
    img.classList.add('loaded');
}